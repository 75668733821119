<template>
  <section>
    <div class="profile-common">
      <!-- info overview -->
      <div class="wrap-overview">
        <div class="content-info">
          <!-- name -->
          <h2>{{ profile.nickname }}<span>さん</span></h2>

          <!-- point -->
          <div class="point-user">
            <!-- availability points -->
            <div>
              <p>利用可能ポイント</p>
              <!-- CHECK POINT TO SHOW -->
              <strong v-if="user_point.total_point_amount < 10000000">
                {{ user_point.total_point_amount | numberShort }}P
              </strong>
              <strong v-else>
                {{ 9999999 | numberWithCommas }}+P
              </strong>
            </div>

            <!-- waiting point for approval -->
            <div>
              <p>判定中ポイント</p>
              <!-- CHECK POINT TO SHOW -->
              <strong>
                {{ (infoStatisticCommon.waiting_pointback_amount || 0) | numberShort(',', 'P') }}
              </strong>
            </div>
          </div>
        </div>
      </div>

      <!-- invite friend -->
      <section class="section-profile">
        <!-- title -->
        <h3 class="title">
          <img src="../../assets/images/icon/icon-users.svg" alt="icon">お友達紹介
        </h3>

        <!-- show info -->
        <div class="content-invite">
          <div class="info">
            <p>今月の紹介人数</p>
            <strong>{{ infoStatisticCommon.total_invitee_in_month }}<span>人</span></strong>
          </div>
          <div class="info">
            <p>累計紹介人数</p>
            <strong>{{ profile.invite_friend_amount || 0 }}<span>人</span></strong>
          </div>
          <div class="invite-friend">
            <router-link to="/invite-friend">
              <img src="../../assets/images/icon/icon-user-invite.svg" alt="icon">お友達に紹介する
            </router-link>
          </div>
        </div>
      </section>
    </div>

    <div class="section-profile">
      <!-- title -->
      <h3 class="title">
        <img src="../../assets/images/icon/icon-eye.svg" alt="icon">最近見た広告
      </h3>

      <!-- message data empty -->
      <b v-if="pagination.total === 0">該当するデータが見つかりませんでした。</b>

      <!-- list recently seen ads -->
      <ListCategory v-else
                    class="list-ads"
                    :list="recentSeenList"
                    :type-color="typeColor" />

      <!-- pagination -->
      <Pagination class="pagination"
                  :total-page="pagination.totalPages"
                  @change-page = changePage
                  :current-page="pageActive" />
    </div>
  </section>
</template>

<script>
import ListCategory from '@/components/ListCategory'
import store from '@/store'
import { mapActions, mapState } from 'vuex'
import Pagination from '@/components/Pagination'
import Common from '@/mixins/common.mixin'

export default {
  name: 'RecentlySeenAds',

  components: {
    Pagination,
    ListCategory
  },

  mixins: [Common],

  created () {
    const params = {
      page: 1,
      // SP show 8, PC show 12
      perPage: window.matchMedia('(max-width: 767px)').matches ? 8 : 12
    }

    store.dispatch('advertises/getListRecentSeen', params)
  },

  computed: {
    ...mapState('advertises', ['recentSeenList', 'pagination']),
    ...mapState('auth', ['profile', 'user_point']),
    ...mapState('user', ['infoStatisticCommon'])
  },

  data () {
    return {
      typeColor: 'cate-orange',
      params: {
        page: 1,
        perPage: window.matchMedia('(max-width: 767px)').matches ? 8 : 12
      },
      pageActive: 1
    }
  },

  methods: {
    ...mapActions('advertises', ['getListRecentSeen']),

    changePage (page) {
      this.params = {
        ...this.params,
        page: page
      }

      this.pageActive = page
      this.fetchList(this.params)
    },

    fetchList (params = {}) {
      this.getListRecentSeen(params)

      // scroll to top when change page
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss" scoped>
.list-ads {
  margin-bottom: 0;
}
.pagination {
  margin-top: 120px;
  @media #{$info-phone} {
    margin-top: 50px;
  }
}
</style>
